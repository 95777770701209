/* @media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
} */

/* Privacy Page */
#privacy {
  padding: 100px 0;
  background: #f6f6f6;
}

#privacy .privacy-text {
  margin: 30px 0;
  text-align: left;
}

#privacy h2 {
  color: #333;
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 20px;
}

#privacy h3 {
  color: #555;
  font-weight: 500;
  font-size: 22px;
  margin: 30px 0 15px;
  text-align: left;
}

#privacy h4 {
  color: #666;
  font-weight: 500;
  font-size: 18px;
  margin: 20px 0 10px;
  text-align: left;
}

#privacy p {
  color: #666;
  line-height: 1.7;
  margin-bottom: 20px;
  text-align: left;
}

#privacy ul {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 20px;
}

#privacy ul li {
  color: #666;
  line-height: 1.7;
  margin-bottom: 8px;
}

#privacy strong {
  font-weight: 600;
}

#privacy .back-to-home {
  margin-top: 50px;
  text-align: center;
}

#privacy .btn-custom {
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  color: white;
  border: 0;
  padding: 14px 34px;
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
}

#privacy .btn-custom:hover {
  background-color: #6372ff;
  background-image: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: white;
}
